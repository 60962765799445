
import Vue from "vue"
import type { Partner } from "@/types/api/partner"

export default Vue.extend({
  data: () => ({
    partners: [] as Partner[],
  }),

  async fetch () {
    const [res, err] = await this.$api.partner.getPartners({
      params: {
        "filter[front]": true,
        "sort[list]": "asc",
      },
    })
    if (err) console.error(err)
    else this.partners = this.$api.helpers.getList(res)
  },

  methods: {
    getLogo (partner: Partner): string {
      return partner?.logo?.ru?.url || ""
    },
  },
})
