// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bNwQtBSU>*+*{margin-top:max(1.5rem,min(2.5vw,3rem))}.lzOVkhXp{display:grid;grid-template-columns:repeat(auto-fit,minmax(max(7.5rem,min(16.67vw,20rem)),1fr))}.Y7-cicmz{align-items:center;display:flex;justify-content:center;padding:max(1.88rem,min(4.17vw,5rem))}.rLlVYg59{height:auto;max-height:max(2.67rem,min(3.96vw,4.75rem));-o-object-fit:contain;object-fit:contain;width:max(6.25rem,min(8.33vw,10rem))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"container": "bNwQtBSU",
	"partners": "lzOVkhXp",
	"partner": "Y7-cicmz",
	"partnerLogo": "rLlVYg59"
};
module.exports = ___CSS_LOADER_EXPORT___;
