
import Vue from "vue"
import AOS from "aos"
import "aos/dist/aos.css"

export default Vue.extend({
  layoutSettings: {
    fullWidth: true,
  },

  mounted () {
    setTimeout(() => { AOS.init({ once: true }) }, 150)
  },
})
