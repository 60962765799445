import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2a7bb4ca"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=2a7bb4ca&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IndexHero: require('/app/components/index/IndexHero.vue').default,AppVideo: require('/app/components/app/AppVideo.vue').default,IndexCalendar: require('/app/components/index/calendar/IndexCalendar.vue').default,IndexInventor: require('/app/components/index/IndexInventor.vue').default,IndexNews: require('/app/components/index/IndexNews.vue').default,IndexVideos: require('/app/components/index/IndexVideos.vue').default,IndexPartners: require('/app/components/index/IndexPartners.vue').default})
