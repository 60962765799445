
import Vue from "vue"
import { mapState } from "vuex"
import debounce from "lodash/debounce"
import type { ProgramEvent } from "@/types/api/program"
import breakpoints from "@/assets/style/_breakpoints.scss"

export type ProgramEventWithDate =
  Omit<ProgramEvent, "start"> & { start: NonNullable<ProgramEvent["start"]> }

export default Vue.extend({
  data: () => ({
    events: [] as ProgramEventWithDate[],
    isMobile: true,
    showOld: false,
  }),

  async fetch () {
    const [res, err] = await this.$api.program.getEvents({
      params: {
        "filter[type]": "event",
        "paginator[limit]": 1000,
      },
    })
    if (err) {
      console.error(err)
      return
    }

    const allEvents: ProgramEvent[] = this.$api.helpers.getList(res)
    // Только мероприятия с датой
    this.events = allEvents.filter((e): e is ProgramEventWithDate => {
      return e.start !== null
    })
  },

  computed: {
    ...mapState("app", ["showEventsFromYear"]),

    activeEvents (): ProgramEventWithDate[] {
      const { events } = this
      return this.showOld
        ? events
        : events.filter((e) => {
          const date = new Date(e.start)
          return date.getFullYear() >= this.showEventsFromYear
        })
    },
  },

  mounted () {
    this.handleResize()
    window.addEventListener("resize", this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.handleResize)
  },

  methods: {
    handleResize: debounce(function (this: any) {
      this.isMobile = window.innerWidth <= breakpoints.md
    }, 300),
  },
})
