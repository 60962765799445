// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bRupgbZt{align-items:center;color:var(--color-primary);display:flex;font-size:.88rem;font-weight:600;line-height:1.2;width:-moz-max-content;width:max-content}.bRupgbZt>*+*{margin-left:.5rem}.AaZgaAWG{--size:0.63rem;margin-bottom:.1em}@media(hover:hover){a.bRupgbZt:hover{-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){a.bRupgbZt:active{-webkit-text-decoration:underline;text-decoration:underline}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"link": "bRupgbZt",
	"linkIcon": "AaZgaAWG"
};
module.exports = ___CSS_LOADER_EXPORT___;
