// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EoD9Abh1 .l2ZMKjmg{font-size:1rem}@media(max-width:667.98px){.EoD9Abh1 .l2ZMKjmg{width:100%}}.SRBUPjVK{display:flex}.SRBUPjVK>* img{height:2.5rem;-o-object-fit:contain;object-fit:contain;width:auto}.SRBUPjVK>*+*{margin-left:max(1rem,min(1.56vw,1.88rem))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"container": "EoD9Abh1",
	"btn": "l2ZMKjmg",
	"organizers": "SRBUPjVK"
};
module.exports = ___CSS_LOADER_EXPORT___;
