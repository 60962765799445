
import Vue from "vue"

export default Vue.extend({
  props: {
    to: { type: String as VuePropType<string | undefined>, default: undefined },
    href: { type: String as VuePropType<string | undefined>, default: undefined },
    text: { type: String, required: true },
  },

  computed: {
    is (): string {
      if (this.href) return "a"
      if (this.to) return "nuxt-link"
      return "span"
    },
  },
})
