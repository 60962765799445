
import {
  defineComponent, ref, computed, unref, onMounted, onUnmounted,
} from "vue"
import { detectIos } from "@/utils/_"

export default defineComponent({
  props: {
    path: { type: String, required: true },
    noWebp: { type: Boolean, default: false },
  },

  setup () {
    // Используем встроенный интерфейс на ios
    const isIos = ref(false)

    const fullscreenBtnTitle = "Во весь экран"
    const muted = ref(true)
    const muteBtnTitle =
      computed(() => muted.value ? "Включить звук" : "Выключить звук")

    const video = ref<HTMLVideoElement | null>(null)
    const paused = ref(false)
    const inFullscreen = ref(false)

    // Запускаем или приостанавливаем видео при клике
    function onVideoClick () {
      if (isIos.value) return

      const el = unref(video)
      if (!el) return
      if (el.paused || el.ended) {
        paused.value = false
        el.play()
      } else {
        paused.value = true
        el.pause()
      }
    }

    function openFullscreen () {
      if (isIos.value) return

      try {
        video.value?.requestFullscreen?.()
      } catch {}
    }

    // Отслеживаем состояние фулскрина
    function onFullscreenChange () {
      const el = unref(video)
      if (!el) return
      inFullscreen.value = !inFullscreen.value
      paused.value = el.paused
    }

    onMounted(() => {
      isIos.value = detectIos()

      // С паузой, т. к. иногда состояние не успевает обновиться
      setTimeout(() => {
        const el = unref(video)
        if (!el) return
        // https://stackoverflow.com/a/6877530
        paused.value = !(
          el.currentTime > 0.1 &&
          !el.paused &&
          !el.ended &&
          el.readyState > 2
        )
      }, 500)

      document.addEventListener("fullscreenchange", onFullscreenChange)
    })

    onUnmounted(() => {
      document.removeEventListener("fullscreenchange", onFullscreenChange)
    })

    return {
      NO_CONTROLS: true,

      isIos,

      fullscreenBtnTitle,
      muted,
      muteBtnTitle,

      video,
      paused,
      inFullscreen,
      onVideoClick,
      openFullscreen,
    }
  },
})
