
import type { SwiperOptions } from "swiper"
import indexSwiperMixin from "@/mixins/index-swiper"
import type { NewsItem } from "@/types/api/news"

export default indexSwiperMixin.extend({
  data: () => ({
    news: [] as NewsItem[],
  }),

  async fetch () {
    const [res, err] = await this.$api.news.getNews({
      params: {
        "paginator[limit]": 12,
        "filter[type]": "news",
      },
    })
    if (err) console.error(err)
    else this.news = this.$api.helpers.getList(res)
  },

  computed: {
    swiperOptions (): SwiperOptions {
      return {
        spaceBetween: 20,
        slidesPerView: "auto",
        navigation: {
          prevEl: `#${this.prevId}`,
          nextEl: `#${this.nextId}`,
          disabledClass: "index-swiper-nav-disabled",
        },
      }
    },
  },
})
