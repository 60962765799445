// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4cgl8Us4>*+*{margin-top:max(2rem,min(2.92vw,3.5rem))}@media(min-width:1023.98px){._5ojCx0En{display:none}}@media(max-width:1023.98px){.vDVojjjC{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"container": "_4cgl8Us4",
	"mobile": "_5ojCx0En",
	"desktop": "vDVojjjC"
};
module.exports = ___CSS_LOADER_EXPORT___;
